/**
 * This example component demonstrates react-in-angularjs. It is not used in the application.
 * This component showcases how props and functions can be used to pass state from AngularJS to React components.
 *
 * @example
 * <Message
 *  ng-repeat="message in Message.messages track by $index"
 *  key-value="$index"
 *  do-click="message.remove()"
 *  type="message.type"
 *  content="message.text">
 * </Message>
 */

/**
 * Note: react-in-angularjs also enables React components to access AngularJS services (e.g., getService('Message')),
 * but with certain limitations. Variables linked to AngularJS services are not reactive within React's system,
 * meaning service changes won't automatically cause React components to re-render, even with hooks.
 * The most effective approach for synchronization is using props, as they align well with React's reactivity and are straightforward.
 * Alternative methods like event listeners or periodic polling can be used but are generally less efficient.
 */

import { useCallback, useEffect } from 'react';
import { message } from 'antd';
import { angularize } from 'react-in-angularjs';

type MessageType = 'info' | 'success' | 'warning' | 'error';

const MessageDurations: Record<MessageType, number> = {
    info: 5,
    success: 5,
    warning: 7,
    error: 10
};

type Props = {
    keyValue: string;
    type: MessageType;
    content: string;
    doClick: () => void;
};

const MessageExample = ({ keyValue, type, content, doClick }: Readonly<Props>) => {
    const [messageApi, contextHolder] = message.useMessage();

    const openMessage = useCallback(
        (messageType: MessageType, messageContent: string) => {
            messageApi.open({
                key: keyValue,
                type: messageType,
                content: messageContent,
                duration: MessageDurations[messageType],
                onClick: () => {
                    messageApi.destroy(keyValue);
                    doClick();
                }
            });
        },
        [keyValue, messageApi, doClick]
    );

    useEffect(() => {
        openMessage(type, content);
    }, [type, content, openMessage]);

    return <>{contextHolder}</>;
};

angularize(MessageExample, 'message', angular.module('uasApp'), {
    keyValue: '<',
    type: '<',
    content: '<',
    doClick: '&'
});

export default MessageExample;
