import { CodeOutlined, FileOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button } from '@timeedit/ui-components';
import { Space } from 'antd';

export enum ContentTypeEnum {
  RAW = 'raw',
  JSON = 'json',
  XML = 'xml'
}

type ResponseFormatButtonsProps = {
  onFormatSelect: (type: ContentTypeEnum) => void;
}

const ResponseFormatButtons = ({ onFormatSelect }: Readonly<ResponseFormatButtonsProps>) => {
  return (
    <Space>
      <Button
        onClick={() => onFormatSelect(ContentTypeEnum.JSON)}
        size="small"
        icon={<FileTextOutlined />}
      >
        JSON
      </Button>
      <Button
        onClick={() => onFormatSelect(ContentTypeEnum.XML)}
        size="small"
        icon={<CodeOutlined />}
      >
        XML
      </Button>
      <Button
        onClick={() => onFormatSelect(ContentTypeEnum.RAW)}
        size="small"
        icon={<FileOutlined />}
      >
        Raw
      </Button>
    </Space>
  );
};

export default ResponseFormatButtons;