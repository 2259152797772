import { Tooltip } from 'antd';

interface ValidLabelProps {
    tooltip: string;
    valid: boolean;
}

export default function ValidLabel({ tooltip, valid }: Readonly<ValidLabelProps>) {
    return (
        <Tooltip title={tooltip}>
            <i
                className={`
          required-label fa
          text-${valid ? 'success fa-check' : 'danger fa-asterisk'}
        `}></i>
        </Tooltip>
    );
}
