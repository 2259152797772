import { useEffect, useState } from 'react';
import { auto as ngAuto } from 'angular';
import { Empty, message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import xmlFormat from 'xml-formatter';
import CancelButton from '../buttons/CancelButton';
import CopyButton from '../buttons/CopyButton';
import { ContentTypeEnum } from '../buttons/ResponseFormatButtons';

type ClipboardModalProps = {
  content?: string;
  type: ContentTypeEnum;
  isOpen: boolean;
  onClose: () => void;
  injector?: ngAuto.IInjectorService;
}

const ClipboardModal = ({ content, type, isOpen, onClose, injector }: Readonly<ClipboardModalProps>) => {
  const [formattedContent, setFormattedContent] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const translateFilter: (input: string) => string = injector ? injector.get('$filter')('translate') : ((input: string) => input);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  useEffect(() => {
    if (content) {
      try {
        let formatted;
        switch (type) {
          case 'json':
            formatted = JSON.stringify(JSON.parse(content), null, 2);
            break;
          case 'xml':
            formatted = xmlFormat(content);
            break;
          default:
            formatted = content;
        }
        setFormattedContent(formatted);
      } catch {
        setFormattedContent(content);
      }
    }
  }, [content, type]);

  const handleCopy = () => {
    if (formattedContent) {
      navigator.clipboard.writeText(formattedContent)
        .then(() => message.success(translateFilter('Static.Component.Clipboard.Success')))
        .catch(() => message.error(translateFilter('Static.Component.Clipboard.Error')));
    }
  };

  return (
    <Modal
      title={translateFilter('Static.Component.Clipboard')}
      open={isModalOpen}
      onCancel={handleClose}
      footer={[
        <CancelButton
          key="cancel"
          onClick={handleClose}
          type="default"
        />,
        <CopyButton
          key="copy"
          onClick={handleCopy}
        />
      ]}
    >
      { formattedContent ? (
        <TextArea
          value={formattedContent}
          rows={10}
          readOnly
        />
      ) : (
        <Empty
          description={translateFilter('Static.Component.Clipboard.Empty')}
          className="m-t-10 m-b-10"
        />
      )}
    </Modal>
  );
};

export default ClipboardModal;