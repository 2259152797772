import { Button } from '@timeedit/ui-components';
import { ButtonType } from 'antd/es/button';

type CloseButtonProps = {
  onClick: () => void;
  type?: ButtonType
}

export default function CloseButton({ onClick, type = 'primary' } : Readonly<CloseButtonProps>) {
  return (
    <Button
      type={type}
      onClick={onClick}>
      Close
    </Button> /* Static.Button.Close */
  );
}