import { CopyOutlined } from '@ant-design/icons';
import { Button } from '@timeedit/ui-components';

type CopyButtonProps = {
  onClick: () => void;
}

export default function CopyButton({ onClick } : Readonly<CopyButtonProps>) {
  return (
    <Button
      type="primary"
      onClick={onClick}
      icon={<CopyOutlined/>}>
      Copy to clipboard
    </Button> /* Static.Button.CopyToClipboard */
  );
}