import React, { PropsWithChildren, useEffect, useState } from 'react';
import { auto as ngAuto } from 'angular';
import { Modal as AntdModal } from 'antd';
import { angularize } from 'react-in-angularjs';
import CloseButton from '../../buttons/CloseButton';
import RequestLogDetailModalContents, { RequestLog } from './RequestLogDetailModalContents';

type ModalProps = {
  requestLog: RequestLog;
  injector: ngAuto.IInjectorService;
};

const RequestLogDetailModal = ({ requestLog, injector } : PropsWithChildren<ModalProps>) => {
  const [isModalOpen, setIsModalOpen] = useState(!!requestLog);
  const translateFilter: (input: string) => string = injector ? injector.get('$filter')('translate') : ((input: string) => input);

  useEffect(() => {
    setIsModalOpen(!!requestLog);
  }, [requestLog]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AntdModal
        title={translateFilter('Static.Page.RequestLog')}
        open={isModalOpen}
        onCancel={handleClose}
        width={650}
        footer={() => (
          <CloseButton
            onClick={handleClose}
            type="primary"
          />
      )}
      >
        <RequestLogDetailModalContents
          requestLog={requestLog}
          injector={injector}
        />
      </AntdModal>
    </>
  );
};

angularize(RequestLogDetailModal, 'requestLogDetailModal', angular.module('uasApp'), {
  requestLog: '<?',
  injector: '<?'
});

export default RequestLogDetailModal;