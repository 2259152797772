'use client';

import { createElement, useEffect, useMemo, useRef, useState } from 'react';
import { auto as ngAuto, IScope } from 'angular';
import { kebabCase } from 'lodash';

/**
 * Angular may try to bind back a value via 2-way binding, but React marks all
 * properties on `props` as non-configurable and non-writable.
 *
 * If we use a `Proxy` to intercept writes to these non-writable properties,
 * we run into an issue where the proxy throws when trying to write anyway,
 * even if we `return false`.
 *
 * Instead, we use the below ad-hoc proxy to catch writes to non-writable
 * properties in `object`, and log a helpful warning when it happens.
 */
function writable<T extends object>(object: T): T {
  const writeableObject = {} as T;

  Object.entries(object).forEach(([key, value]) => {
    if (!object.hasOwnProperty(key)) return;

    Object.defineProperty(writeableObject, key, {
      get() {
        return value;
      },
      set(newValue: any) {
        const d = Object.getOwnPropertyDescriptor(object, key);
        if (d?.writable) {
          value = newValue;
          return newValue;
        }
        console.warn(
          `Tried to write to non-writable property "${key}" of`,
          object,
          '. Consider using a callback instead of 2-way binding.'
        );
      }
    });
  });

  return writeableObject;
}

interface AngularComponentProps {
  [key: string]: any;
  injector: ngAuto.IInjectorService;
  component: string;
}

export default function Reacterize({
  component,
  injector,
  ...props
}: AngularComponentProps) {
  const [didInitialCompile, setDidInitialCompile] = useState(false);
  const [angularScope, setAngularScope] = useState<IScope>();

  useEffect(() => {
    const assignedAngularScope = Object.assign(
      injector.get('$rootScope').$new(true),
      { props: writable(props) }
    );
    setAngularScope(assignedAngularScope);

    return () => {
      assignedAngularScope.$destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!angularScope) return;
    if (!ElementRef.current) return;
    if (didInitialCompile) return;

    injector.get('$compile')(ElementRef.current)(angularScope);
    angularScope.$digest();

    setDidInitialCompile(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [angularScope]);

  useEffect(() => {
    if (!angularScope) return;
    if (!didInitialCompile) return;

    (angularScope as any).props = writable(props);
    angularScope.$digest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const ElementRef = useRef<HTMLDivElement>(null);

  const getAngularBindings = useMemo(
    () =>
      Object.keys(props).reduce(
        (prev, key) => ({ ...prev, [key]: `props.${key}` }),
        {}
      ),
    [props]
  );

  if (!angularScope) return <></>;

  return createElement(kebabCase(component), {
    ...getAngularBindings,
    ref: ElementRef
  });
}
