import { Button } from '@timeedit/ui-components';
import { ButtonType } from 'antd/es/button';

type CancelButtonProps = {
  onClick: () => void;
  type?: ButtonType
}

export default function CancelButton({ onClick, type = 'primary' } : Readonly<CancelButtonProps>) {
  return (
    <Button
      type={type}
      onClick={onClick}>
      Cancel
    </Button> /* Static.Button.Cancel */
  );
}