import { useEffect, useId, useRef, useState } from 'react';
import { capitalize, lowerCase } from 'lodash';
import Quill, { TextChangeHandler } from 'quill';
import QuillBetterTablePlus from 'quill-better-table-plus';
import { angularize } from 'react-in-angularjs';
import { applyQuillFormat, getQuillConfig, isQuillEmpty } from '../../services/quill.service';
import type { QuillTextEditorProps } from '../../types/quill.types';
import ValidLabel from '../label/ValidLabel.component';
import LanguageFlag from '../languageFlag/LanguageFlag.component';
import TagTooltips from './TagTooltips.component';

// Create custom `onPaste` event for quill to handle pasting HTML
const Clipboard = Quill.import('modules/clipboard');
class PlainClipboard extends Clipboard {
    onPaste(range: any, { text, html }: any) {
        if (!this.quill.clipboard.options.stripHtml) {
            super.dangerouslyPasteHTML(0, html);
            return;
        }

        super.onPaste(range, { text });
    }
}

Quill.register(
    {
        'modules/clipboard': PlainClipboard
    },
    true
);

// Register beter-table-plus
Quill.register(
    {
        'modules/better-table-plus': QuillBetterTablePlus
    },
    true
);

// Quill uses <strong> by default
const bold = Quill.import('formats/bold');
bold.tagName = 'b';
Quill.register(bold, true);

// Quill uses <em> by default
const italic = Quill.import('formats/italic');
italic.tagName = 'i';
Quill.register(italic, true);

const QuillTextEditor = ({
    value,
    stripHtml,
    disabled,
    tags,
    languageData,
    requiredData,
    formats,
    readOnly,
    placeholder,
    toolbar,
    onQuillChange,
    onQuillSelectionChange,
    showQuillHtml
}: Readonly<QuillTextEditorProps>) => {
    const id = useId();

    // predefine refs
    const quillRef = useRef<Quill | null>(null);
    const quillToolbarRef = useRef<HTMLDivElement | null>(null);
    const quillEditorRef = useRef<HTMLDivElement | null>(null);

    // update inner html to correct allow spell check
    const setSpellCheck = () => {
        if (!quillEditorRef.current) {
            return;
        }
        const language = lowerCase(languageData.language);

        const paragraphs = quillEditorRef.current.querySelectorAll('.ql-editor p') as NodeListOf<HTMLParagraphElement>;
        paragraphs.forEach((paragraph) => {
            if (disabled) {
                paragraph.removeAttribute('contenteditable');
            } else {
                paragraph.setAttribute('contenteditable', '');
            }

            paragraph.setAttribute('spellcheck', 'true');
            paragraph.setAttribute('lang', language);
        });
    };

    // Text change event
    const [textValue, setTextValue] = useState<string>(value);
    const onQuillTextChange: TextChangeHandler = () => {
        const { current: editorElem } = quillEditorRef;
        const { current: quill } = quillRef;

        if (!editorElem) {
            return;
        }
        if (!quill) {
            return;
        }

        setSpellCheck();
        const { innerHTML } = editorElem.querySelector('.ql-editor') as HTMLDivElement;
        const text = quill.getText();

        let html = applyQuillFormat(innerHTML, toolbar?.align);
        if (html === '<br/>') {
            html = '';
        }

        const plainText = text.split('');
        // remove last character (/n) added by Quill from the plain text
        plainText.pop();
        onQuillChange({ text: plainText.join(''), html });
        setTextValue(text);
    };

    useEffect(() => {
        const { current: quillToolbar } = quillToolbarRef;
        const { current: quillEditor } = quillEditorRef;
        if (!quillToolbar || !quillEditor) {
            return;
        }

        const quill = new Quill(
            quillEditor,
            getQuillConfig({
                formats,
                readOnly,
                placeholder,
                modules: {
                    toolbar: quillToolbar,
                    clipboard: {
                        stripHtml
                    }
                }
            })
        );

        quillRef.current = quill;

        // set event listener for text change
        quill.on('text-change', onQuillTextChange);
        quill.on('selection-change', onQuillSelectionChange);

        if (value) {
            const delta = quill.clipboard.convert({ html: value });
            quill.setContents(delta, 'silent');
        }

        setSpellCheck();

        return () => {
            quill.off('text-change', onQuillTextChange);
            quill.off('selection-change', onQuillSelectionChange);
        };
    }, []);

    const insertNewTable = () => {
        const { current: quill } = quillRef;
        if (!quill) {
            return;
        }

        const tableModule = quill.getModule('better-table-plus') as any;
        tableModule.insertTable(3, 3);
    };

    return (
        <div>
            <div ref={quillToolbarRef} className="d-f justify-content-between align-items-center">
                <div className="d-f align-items-center">
                    <LanguageFlag language={languageData.language} tooltip={languageData.tooltip} />
                    {toolbar && (
                        <>
                            {['bold', 'italic', 'underline', 'link', 'image', 'video'].map(
                                (toolbarValue) =>
                                    toolbar[toolbarValue] && (
                                        <button
                                            key={`${id}-${toolbarValue}`}
                                            className={`ql-${toolbarValue}`}
                                            title={capitalize(toolbarValue)}></button>
                                    )
                            )}
                            {toolbar.list && (
                                <>
                                    {toolbar.bullet && (
                                        <button className="ql-list" value="bullet" title="Bullet"></button>
                                    )}
                                    {toolbar.ordered && (
                                        <button className="ql-list" value="ordered" title="Ordered"></button>
                                    )}
                                </>
                            )}
                            {toolbar.align && (
                                <>
                                    <button className="ql-align" value="" title="Left"></button>
                                    <button className="ql-align" value="center" title="Center"></button>
                                    <button className="ql-align" value="right" title="Right"></button>
                                </>
                            )}
                            {toolbar.header && (
                                <>
                                    {['h1', 'h2', 'h3', 'h4'].map(
                                        (headingValue) =>
                                            toolbar[headingValue] && (
                                                <button
                                                    key={`${id}-${headingValue}`}
                                                    className="ql-header"
                                                    value={headingValue[1]}
                                                    title={capitalize(headingValue)}></button>
                                            )
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="pull-right d-f align-items-center gap-10 order-3">
                    {toolbar?.table && <i className="fa fa-table" onClick={insertNewTable}></i>}
                    {tags && <TagTooltips tags={tags} />}
                    {!isQuillEmpty(textValue) && showQuillHtml && toolbar?.debug && (
                        <i role="button" onClick={showQuillHtml} className="fa fa-code p-5"></i>
                    )}
                    {requiredData.required && (
                        <ValidLabel tooltip={requiredData.tooltip} valid={!isQuillEmpty(textValue)} />
                    )}
                </div>
            </div>
            <div ref={quillEditorRef}></div>
        </div>
    );
};

angularize(QuillTextEditor, 'quillTextEditor', angular.module('uasApp'), {
    languageData: '<?',
    requiredData: '<?',
    tags: '<?',
    formats: '<?',
    readOnly: '<?',
    placeholder: '<?',
    toolbar: '<?',
    format: '<?',
    stripHtml: '<?',
    value: '<',
    showQuillHtml: '&?',
    onQuillChange: '&?',
    onQuillSelectionChange: '&?'
});
