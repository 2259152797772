import React, { useMemo } from 'react';
import { Alert } from 'antd';
import { isEmpty, truncate } from 'lodash';

type RequestLogDetailExceptionProps = {
  message?: string;
}

export default function RequestLogDetailException({ message } : Readonly<RequestLogDetailExceptionProps>) {
  const exception = useMemo(() => {
    if (isEmpty(message)) {
      return null;
    }
    return truncate(message, { length: 700, omission: '...' });
  }, [message]);

  return (
    exception ? (
      <Alert
        message="Exception"
        description={<pre className="pre-wrap word_break--word border--none background--none">{exception}</pre>}
        type="error"
        showIcon
      />
    ) : null
  );
}