import React, { useState } from 'react';
import { auto as ngAuto } from 'angular';
import { Descriptions, DescriptionsProps, Space } from 'antd';
import Reacterize from '../../../Reacterize';
import ResponseFormatButtons, { ContentTypeEnum } from '../../buttons/ResponseFormatButtons';
import ClipboardModal from '../../clipboard/ClipboardModal';
import RequestLogDetailException from './RequestLogDetailException';

type RequestLogDetailModalProps = {
  requestLog: RequestLog;
  injector: ngAuto.IInjectorService;
};

export type RequestLog = {
  id: string;
  request?: string;
  response?: string;
  action?: string;
  person?: object;
  remoteAddress?: string;
  requestDate?: Date;
  responseDate?: Date;
  message?: string;
  messageCount?: number,
  errorCount?: number,
  ok?: boolean;
  status?: number;
  method?: string;
  encoding?: string;
  headers?: string;
  reference?: EntityReference;
  correlationId?: string;
}

type EntityReference = {
  id?: string;
  type?: string;
}

export default function RequestLogDetailModalContents({ requestLog, injector } : Readonly<RequestLogDetailModalProps>) {
  const [clipboardModalContent, setClipboardModalContent] = useState<string | undefined>(undefined);
  const [clipboardModalType, setClipboardModalType] = useState<ContentTypeEnum>(ContentTypeEnum.RAW);
  const [isClipboardModalOpen, setIsClipboardModalOpen] = useState<boolean>(false);

  const openClipboardModal = (data: string | undefined, type: ContentTypeEnum) => {
    setClipboardModalContent(data);
    setClipboardModalType(type);
    setIsClipboardModalOpen(true);
  };

  const configDateTimeFilter: (input: Date | undefined) => string = injector.get('$filter')('configDateTime');

  const baseItems: DescriptionsProps['items'] = [
    {
      label: 'IP',
      children: requestLog.remoteAddress
    },
    {
      label: 'User',
      children:
        <>
          { requestLog && requestLog.person && (
            <Reacterize
              component="person-label"
              person={requestLog.person}
              injector={injector}
            />
          )}
        </>
    },
    {
      label: 'Action',
      children: requestLog.action
    },
    {
      label: 'Correlation ID',
      children: requestLog.correlationId
    }
  ];

  const requestItems: DescriptionsProps['items'] = [
    {
      label: 'Request method',
      children: requestLog.method
    },
    {
      label: 'Request date',
      children: configDateTimeFilter(requestLog.requestDate)
    },
    {
      label: 'Request encoding',
      children: requestLog.encoding
    },
    {
      label: 'Request headers',
      children: requestLog.headers
    },
    {
      label: 'Request body',
      children: requestLog?.request ? (
        <ResponseFormatButtons
            onFormatSelect={(type) => openClipboardModal(requestLog.request, type)}
        />
      ) : null
    }
  ];

  const responseItems: DescriptionsProps['items'] = [
    {
      label: 'Response status',
      children: requestLog.status
    },
    {
      label: 'Response date',
      children: configDateTimeFilter(requestLog.responseDate)
    },
    {
      label: 'Response body',
      children: requestLog?.response ? (
        <ResponseFormatButtons
            onFormatSelect={(type) => openClipboardModal(requestLog.response, type)}
        />
      ) : null
    }
  ];

  const messageItems: DescriptionsProps['items'] = [
    {
      label: 'Message count',
      children:
        <div>
          {requestLog.messageCount && requestLog.messageCount > 0 ? (
            <span className="badge">
              {requestLog.messageCount}
            </span>
          ) : null}

          {requestLog.errorCount && requestLog.errorCount > 0 ? (
            <span className="badge badge-danger">
              {requestLog.errorCount}
            </span>
          ) : null}
        </div>
    },
    {
      label: 'Messages',
      children:
        <Reacterize
          component="job-messages"
          source-type="request-log"
          source-id={requestLog.id}
          injector={injector}
        />
    }
  ];

  const sections: DescriptionsProps['items'][] = [
    baseItems,
    requestItems,
    responseItems,
    messageItems
  ];

  return (
    <>
      <Space direction="vertical">
        {sections.map((items, index) => (
          <Descriptions
            key={index}
            className="m-b-15"
            layout="horizontal"
            items={items}
            column={1}
            size="small"
            bordered={false}
            labelStyle={{ display: 'block', minWidth: '160px', textAlign: 'right' }}
          />
        ))}

        <RequestLogDetailException
          message={requestLog.message}
        />
      </Space>

      <ClipboardModal
        content={clipboardModalContent}
        type={clipboardModalType}
        isOpen={isClipboardModalOpen}
        onClose={() => setIsClipboardModalOpen(false)}
        injector={injector}
      />
    </>
  );
}